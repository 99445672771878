import './bootstrap.sass'
import './App.css'

function App() {
  return (
    <div className="container">
      <img className="pt-md-5 img-fluid" src={`${process.env.PUBLIC_URL}/pic.jpg`} alt="" />
      <div className="row pt-sm-3 pt-md-4 justify-content-center">
        <h1 className="col">Kyle Kurtenbach</h1>
      </div>
      <div className="row justify-content-center"><div className="col">Software Engineer</div></div>
      <div className="row justify-content-center"><div className="col">Chicago, IL</div></div>
      <div className="row pt-sm-3 pt-md-5">
        <div className="col-1"><a href="https://github.com/kurtenbachkyle/">github</a></div>
        <div className="w-100 d-sm-block d-none "></div>
        <div className="col-1"><a href="https://www.linkedin.com/in/kyle-kurtenbach-40851049/">linkedin</a></div>
      </div>
    </div>
  );
}

export default App;
